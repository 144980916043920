import React from 'react';
import '../css/Construction.css';
import Navbar from './Navbar';
import Footer from './Footer';
import Logo from '../img/hyperleague blanco@4x.png';
import placeholder from '../img/league/marcet.png';
import { useTranslation } from 'react-i18next';

import bayerIcon from '../img/clubs/club.png';


const clubIcons = Array.from({ length: 19 }, () => bayerIcon);

const UnderConstruction = () => {

  return (
    <div className="construction-container">
    
            <img src={Logo}></img>
            <p>Sitio en construcción</p>
 

    </div>
  );
};

export default UnderConstruction;
