import React from 'react';
import '../css/Home.css';
import Navbar from './Navbar';
import Footer from './Footer';
import Logo from '../img/Recurso 2@4x.png';
import placeholder from '../img/league/marcet.png';
import { useTranslation } from 'react-i18next';

import bayerIcon from '../img/clubs/club.png';

const clubIcons = Array.from({ length: 19 }, () => bayerIcon);

const Home = () => {
  const { t } = useTranslation();
  return (
    <div className="home-container">
      <Navbar />
      {/* <section className="clubs-section">
        <div className="clubs-bar">
          {clubIcons.map((icon, index) => (
            <img 
              key={index} 
              src={icon}
              alt={`Club ${index + 1}`} 
              className="club-icon" 
            />
          ))}
        </div>
      </section> */}
      <main className="main-content">
        <section className="hero-section">
          <div className="content-wrapper">
            <div className="video-container">
              <div className="star"></div>
              <iframe 
                width="600" 
                height="315" 
                src="https://www.youtube.com/embed/8fxag-gpoF8?si=d8BUFUhH0jTj_1f1" 
                title="YouTube video player" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen>
              </iframe>
            </div>
            <div className="hero-text">
            <h1>{t('introTitle')}</h1>
            <p className="special-font">{t('introDescription')}</p>
            </div>
          </div>
          <div class="logo-position">
            <img src={Logo} alt="Logo" class="logo-image"/>
          </div>
        </section>
      <div className='home-content'>
        <section className="leagues-section content-wrapper">
          <div className="section-header">
            <h2>HYPER LEAGUES</h2>
          </div>
          <p>Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.</p>
          <div className="leagues-grid">
          <a href="/hypearleague/SUB19-Grupo-1">
            <div className="league-card">
              <div className="card-content">
                <h3>SUB19 GRUPO 1</h3>
              </div>
            </div>
            </a>
            <a href="/hypearleague/SUB19-Grupo-2">
            <div className="league-card">
              <div className="card-content">
                <h3>SUB19 GRUPO 2</h3>
              </div>
            </div>
            </a>
            <a href="/hypearleague/SUB16-Grupo-1">
            <div className="league-card">
              <div className="card-content">
                <h3>SUB16 GRUPO 1</h3>
              </div>
            </div>
            </a>
            <a href="/hypearleague/SUB16-Grupo-2">
            <div className="league-card">           
              <div className="card-content">
                <h3>SUB16 GRUPO 2</h3>
              </div>
            </div>
            </a>
           
          </div>
        </section>
      </div>

        <section className="leagues-section content-wrapper">
        <h1>¿QUÉ ES LA HYPER LEAGUE?</h1>
          <p>Hyper League es un torneo de prestigio impulsado por la Federación Catalana de Fútbol, representante de la Federación Española en Barcelona y Cataluña que se juega a lo largo de toda la temporada (septiembre-junio).</p>
          <p>Está meticulosamente diseñado para equipos y jugadores que demuestran un estándar competitivo en notable progresión.</p>
          <p>Esta competición es más que un conjunto de partidos; es un escaparate del talento emergente en el fútbol. Aquí, los jóvenes jugadores tienen la oportunidad única de captar la atención de cazatalentos de los clubes profesionales más destacados de España y Europa y de los entrenadores de las más prestigiosas universidades de USA.</p>
          <p>Participar en la Hyper League es un privilegio reservado para los equipos y jugadores que poseen la licencia NG pro 1, garantizando así que cada encuentro sea de la más alta calidad. Esta exclusividad asegura que los jugadores están compitiendo con lo mejor de lo mejor en cada nivel competitivo, proporcionando un entorno donde pueden medir sus habilidades contra jugadores de igual calibre emocional.</p>
          <p>Cada partido en el Showdown es una oportunidad para que los jóvenes futbolistas demuestren su habilidad, pasión y potencial para alcanzar las metas de su Plan Vital en el fútbol.</p>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Home;
